<template>
  <div
    v-if="show"
    class="linear-block">
    <p
      class="linear-block__title">
      {{ title }}
    </p>
    <v-progress-linear
      color="lightBlue"
      indeterminate
      rounded
      bottom
      :height="$vuetify.breakpoint.mdAndUp ? 6 : 3" />
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'AppLoadingSpinner',
  computed: {
    ...mapState(['progressLinear']),
    show() {
      return this.progressLinear.show;
    },
    title() {
      return this.progressLinear.title;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';
  .linear-block {
    position: absolute;
    bottom: 0;
    width: 100%;
    flex-direction: column;
    z-index: 111111;
    background: #fff;
    &__title {
      text-align: center;
      font-size: 13px;
      margin-bottom: 0;
      @media #{map-get($display-breakpoints, 'sm-and-down')} {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }
</style>

